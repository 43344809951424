.tag0{
    font-size: 30px;
}
.tag1{
    font-size: 60px;
}
.tag2{
    font-size: 30px;
}
.tag3{
    font-size: 25px;
    padding: 20px;
    padding-inline: 50px;
}
.tag4{
    font-size: 60px;
    margin-top: 80px;
}
.tag5{
    font-size: 28px;
}
.tag6{
    font-size: 40px;
}


.box1{
    width: 380px;
}


.container1{
    margin: 20px;
    margin-inline: 100px;
}
.container2{
    display: flex;
    flex-direction: row;
}
.container3{
    height: 100%;
}
.container4{
    height: auto;
}
.container5{
    display:flex;
    flex-direction: row;
    gap: 100px;
}


.form{
    width: 500px;
}

@media only screen and (max-device-width: 480px) {
    .tag0{
        font-size: 20px;
    }
    .tag1{
        font-size: 30px;
    }
    .tag2{
        font-size: 18px;
    }
    .tag3{
        font-size: 20px;
        padding: 10px;
        padding-inline: 20px;
    }
    .tag4{
        font-size: 30px;
        margin-top:50px;
    }
    .tag5{
        font-size: 15px;
    }
    .tag6{
        font-size: 18px;
    }

    .box1{
        width: fit-content;
    }
    .container1{
        margin: 10px;
        margin-inline: 20px;
    }
    .container2{
        flex-direction: column;
        flex-wrap: wrap;
    }
    .container3{
        height:auto;
    }
    .container4{
        height:700px
    }
    .container5{
        flex-direction: column;
        gap: 0;
    }

    .form{
        width: auto;
    }
}